//// grid

$g-gutter: 2.5rem;
$em-factor: 0.0625;
$viewports: (phone: 320, phone-ls: 480, phablet: 560, tablet: 768, desktop: 992, desktop-lg: 1200);

$phone: (map-get($viewports, phone) * ($em-factor * 1em));
$phone-ls: (map-get($viewports, phone-ls) * ($em-factor * 1em));
$phablet: (map-get($viewports, phablet) * ($em-factor * 1em));
$tablet: (map-get($viewports, tablet) * ($em-factor * 1em));
$desktop: (map-get($viewports, desktop) * ($em-factor * 1em));
$desktop-lg: (map-get($viewports, desktop-lg) * ($em-factor * 1em));

//// typography

$font-factors: (big-4: 2.75, big-3: 2.25, big-2: 1.5, big-1: 1.125, normal: 1, small-1: 0.875, small-2: 0.75);

$t-base-font-size: 1.6rem;
$t-base-line-height: 1.3;
$t-space-line: $t-base-font-size * $t-base-line-height;
$t-base-font: Orbitron, sans-serif;
$t-special-font: Orbitron, serif;
$t-icon-font: FontAwesome;
$t-b-4-font-size: (map-get($font-factors, big-4) * $t-base-font-size);
$t-b-3-font-size: (map-get($font-factors, big-3) * $t-base-font-size);
$t-b-2-font-size: (map-get($font-factors, big-2) * $t-base-font-size);
$t-b-1-font-size: (map-get($font-factors, big-1) * $t-base-font-size);
$t-n-font-size: (map-get($font-factors, normal) * $t-base-font-size);
$t-s-1-font-size: (map-get($font-factors, small-1) * $t-base-font-size);
$t-s-2-font-size: (map-get($font-factors, small-2) * $t-base-font-size);
$f-orbitron: Orbitron, sans-serif;
$f-opensans: OpenSans, normal;
$f-fa-free: FontAwesome, normal;
$f-fa-brands: FontAwesomBrands, normal;

//// colors

// basic
$c-black:        #000;
$c-white:        #fff;
$c-gray:         #777;

// brand
$c-concrete:     #f3f3f3;
$c-alto:         #dedede;
$c-silver:       #ccc;
$c-blue-zodiac:  #0d1645;
$c-lucky-point:  #192b71;
$c-cerulean:     #009ee0;
$c-chestnut:     #b94a48;
$c-tacha:        #d7b254;
$c-bismark:      #427288;
$c-crail:        #b94240;
$c-gray-chateau: #a3a8aa;
$c-ch-blue:      #005a90;
$c-ch-input:     rgba($c-ch-blue, 0.2);

$c-ch-block-1:   #4499ba;
$c-ch-block-2:   #a88aa0;
$c-ch-block-3:   #bcb68d;
$c-ch-block-4:   #b58a79;

// order
$c-1: $c-ch-blue;
$c-2: $c-ch-blue;
$c-3: $c-cerulean;
$c-4: $c-black;
$c-5: $c-white;
$c-6: $c-alto;
$c-7: $c-concrete;
$c-8: $c-silver;
$c-s-1: $c-ch-block-1;
$c-s-2: $c-ch-block-2;
$c-s-3: $c-ch-block-3;
$c-s-4: $c-ch-block-4;

// nested
$c-text: $c-4;
$c-link: $c-gray;
$c-link-hover: $c-4;
$c-selection: $c-5;
$c-selection-background: lighten($c-2, 10%);
$c-error: $c-chestnut;

// error
$c-error-text: $c-black;
$c-error-bg: lighten($c-black, 90%);
$c-error-border: $c-black;

//// animations
$a-duration: 0.3s;
$a-function: ease;

$l-input-height: 3.4rem;
$l-input-padding-y: 0.6rem;
$l-input-padding-x: 1.2rem;
$l-input-padding: $l-input-padding-y $l-input-padding-x;
$l-input-border: 0.1rem;
$l-input-border-radius: 0;

// $borderRadius:          0;
// $color-selection-back:  $c-2;
// $color-selection:       $white;
