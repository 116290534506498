.result {
  $group: #{".result__group"};
  $part1: #{$group}--1;
  $part2: #{$group}--2;
  $part3: #{$group}--3;
  $part4: #{$group}--4;

  &__graph-wrap {
    width: 100%;

    &--detail {
      @include media($tablet) {
        width: 75%;
        margin: 0 auto;
      }
    }
  }

  &__graph {
    display: block;
    width: 100%;
    height: auto;
  }

  &__header {
    margin-bottom: $t-space-line;
  }

  &__download-wrap {
    margin-top: $t-space-line;
    text-align: center;

    &:not(#{&}--first) {
      @include media($tablet) {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        margin-top: 0;
        transform: translateY(-50%);
      }

      a {
        @include states() {
          color: $c-1;
        }

        color: $c-5;
      }
    }
  }

  &__legend {
    text-align: center;
  }

  &__graph-call {
    text-align: center;
  }

  &__group {
    margin-bottom: 4rem;

    &__header {
      position: relative;
      padding: 2rem 1.5rem;
      margin: 3rem 0 2rem;
      text-align: center;

      #{$part1} & {
        background-color: $c-s-1;
      }

      #{$part2} & {
        background-color: $c-s-2;
      }

      #{$part3} & {
        background-color: $c-s-3;
      }

      #{$part4} & {
        background-color: $c-s-4;
      }
    }

    &__head-line {
      margin-bottom: 0;
      font-weight: 700;
      color: $c-5;
    }
  }
}
