/* orbitron-regular - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/orbitron-v10-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url("../fonts/orbitron-v10-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* orbitron-500 - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/orbitron-v10-latin-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url("../fonts/orbitron-v10-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* orbitron-700 - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/orbitron-v10-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url("../fonts/orbitron-v10-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* orbitron-900 - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/orbitron-v10-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url("../fonts/orbitron-v10-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* OpenSans-regular - latin */
@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v16-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url("../fonts/open-sans-v16-latin-regular.woff2") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: FontAwesome;
  font-weight: 900;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"); }

.fab {
font-family: FontAwesome;
}
