.form {
  &--registration {
    display: block;
    margin: 0 auto;

    @include media($tablet) {
      max-width: 70%;
    }
  }

  &__checkBoxText {
    font-size: 1.5rem;
  }

  &__group {
    margin-bottom: ($t-space-line * 0.75);
  }

  &__label {
    @include label;
  }

  &__input {
    @include input;
    background-color: $c-ch-input;
  }

  &__error {
    @include error;
  }

  &__button {
    @include button;
  }
}
