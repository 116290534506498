*, ::after, ::before {
  box-sizing: border-box;
}

html, body {
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

html {
  height: 100%;
  overflow-y: auto;
  font-size: $em-factor * 1000%;
}

body {
  @include fontsmoothing;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: $f-opensans;
  font-size: $t-base-font-size;
  line-height: $t-base-line-height;
  color: $c-black;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $t-space-line;
  font-family: $f-orbitron;
  line-height: 1;
  color: $c-ch-blue;
}

h1 {
  font-size: $t-b-4-font-size;
}

h2 {
  font-size: $t-b-3-font-size;
}

h3 {
  font-size: $t-b-2-font-size;
}

h4 {
  font-size: $t-b-1-font-size;
}

h5 {
  font-size: $t-n-font-size;
}

h6 {
  font-size: $t-n-font-size;
}

a {
  @include states {
    color: $c-ch-blue;
    text-decoration: none;
  }

  display: inline-block;
  color: $c-gray;
  transition: all $a-duration $a-function;
}

*::selection {
  color: $c-selection;
  background: $c-selection-background;
}

img::selection {
  background: none;
}

.second-headline {
  font-size: 2.2rem;
  color: $c-black;
}

.site-header {
  flex-shrink: 0;

  &__logo-wrap {
    display: block;
    max-width: 100%;
    margin: 0 auto;

    a {
      display: block;
    }
  }

  &__logo {
    display: block;
    width: 100%;
    height: auto;
  }
}

.site-main {
  flex: 1 0 auto;
}

.contact-info {
  padding: 0;
  margin: 0;
  line-height: 2.6rem;
  text-align: center;
  list-style: none;
  color: $c-ch-blue;

  @include media($tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    padding: 1rem 0;
    color: $c-ch-blue;
  }

  &__icon {
    padding: 0 1rem;
    color: $c-ch-blue;
  }

  &__link {
    @include states {
      color: $c-ch-blue;
    }

    color: $c-ch-blue;
  }
}

.btn-default {
  color: #fff;
  background-color: #005a90;
  border: .2rem solid $c-ch-blue !important;
}

.btn {
  display: inline-block;
  padding: 1rem 1rem;
  font-family: Orbitron,sans-serif;
  font-weight: 700;
  line-height: 1.25;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  border-radius: 0;
}

.btn, .btn:active, .btn:focus {
  text-decoration: none;
}

.btn:hover {
  background-color: $c-white;
  color: $c-ch-blue;
}

.site-footer {
  &__section {
    padding-top: $g-gutter;
    padding-bottom: $g-gutter;
    margin-top: 4em;
    color: $c-white;

    @include media(tablet) {
      padding-top: $g-gutter * 2;
      padding-bottom: $g-gutter * 2;
    }

    &--upper {
      background-color: $c-4;
    }

    &--lower {
      background-color: $c-1;

      &, a {
        color: $c-white;
      }

      a {
        &[href^=tel] {
          @include states("&") {
            color: inherit;
            text-decoration: none;
          }
        }

        @include states("&") {
          text-decoration: none;
        }

        @include states {
          color: $c-black;
        }
      }
    }
  }
}

.socialbar {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  &__item {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    @include states {
      color: $c-black;
    }
  }

  .site-footer & {
    @include media(phablet) {
      justify-content: center;
    }

    @include media(desktop) {
      justify-content: flex-start;
    }
  }
}

.address {
  $self: &;
  color: $c-white;
  font-family: OpenSans;
  font-weight: normal;

  &__paragraph {
    margin-bottom: 0;

    @include media(phablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    display: block;

    @include media(phablet) {
      &::after {
        content: "\7c";
        color: $c-white;
      }

      &:last-child {
        &::after {
          content: none;
          color: $c-white;
        }
      }
    }

    &--title {
      font-family: $f-orbitron;
      font-weight: 900;
      width: 100%;
      color: $c-white;

      &:after {
        font-family: $f-opensans;
        font-weight: 400;
        color: $c-white;
        content: " ";
      }
    }

    &--city {
      width: 50%;
      color: $c-white;

      &:after {
        font-family: $f-opensans;
        font-weight: 400;
        color: $c-white;
        content: " ";
      }
    }

    &--citytwo {
      margin-bottom: 2rem;
      width: 50%;
      color: $c-white;
    }
  }

  .site-footer & {
    #{$self} {
      &__paragraph {
        justify-content: center;

        @include media(desktop) {
          justify-content: flex-start;
        }
      }
    }
  }
}
