.survey {
  $group: #{".survey__group"};
  $part1: #{$group}--1;
  $part2: #{$group}--2;
  $part3: #{$group}--3;
  $part4: #{$group}--4;

  &__participant {
    font-family: OpenSans;
    font-weight: bold;
    font-size: 4rem;
    color: $c-black;
  }

  &__please {
    font-family: OpenSans;
    font-weight: bold;
    font-size: large;
    color: $c-gray;
  }

  &__group {

    &__header {
      padding: 2rem 1.5rem;
      margin: 3rem 0 2rem;
      text-align: center;

      #{$part1} & {
        background-color: $c-s-1;
      }

      #{$part2} & {
        background-color: $c-s-2;
      }

      #{$part3} & {
        background-color: $c-s-3;
      }

      #{$part4} & {
        background-color: $c-s-4;
      }
    }

    &__head-line {
      margin-bottom: 0;
      font-weight: 700;
      color: $c-5;
    }
  }

  &__form-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 7rem;
    margin-bottom: 2rem;
  }

  &__question {
    width: 100%;
    padding-right: 2rem;
    margin: 0 0 1rem;
    font-family: OpenSans;
    font-weight: bold;
    color: $c-ch-blue;

    @include media($desktop) {
      width: 60%;
      margin: 0;
    }

    &__title {
      font-weight: 700;
    }
  }

  &__input-group {
    width: 100%;
    margin-bottom: 2rem;

    @include media($desktop) {
      width: 40%;
      margin: 0;
    }
  }

  &__submit-wrap {
    margin: $t-space-line 0;
    text-align: left;
  }

  &__submit {
    @include button;

    font-size: $t-b-2-font-size;
  }
}

.range-slider {
  $group: #{".survey__group"};
  $part1: #{$group}--1;
  $part2: #{$group}--2;
  $part3: #{$group}--3;
  $part4: #{$group}--4;

  display: flex;
  align-items: center;
  flex-direction: row;

  &__range {
    $track-height: 0.5rem;
    $track-color: $c-8;
    $handle-width: 2rem;
    $handle-height: $handle-width;

    flex-grow: 1;
    padding: 1rem 0;
    margin: 0;
    cursor: pointer;
    background: none;
    border-radius: 0;
    outline: none;
    appearance: none;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      height: $track-height;
      background: $track-color;
    }

    &::-webkit-slider-thumb {
      width: $handle-width;
      height: $handle-height;
      margin-top: -0.7rem;
      cursor: pointer;
      border-radius: 50%;
      appearance: none;

      #{$part1} & {
        background-color: $c-s-1;
      }

      #{$part2} & {
        background-color: $c-s-2;
      }

      #{$part3} & {
        background-color: $c-s-3;
      }

      #{$part4} & {
        background-color: $c-s-4;
      }
    }

    &::-moz-range-track {
      background: $track-color;
    }

    &::-moz-range-thumb {
      width: $handle-width;
      height: $handle-height;
      cursor: pointer;
      border: none;
      border-radius: 50%;

      #{$part1} & {
        background-color: $c-s-1;
      }

      #{$part2} & {
        background-color: $c-s-2;
      }

      #{$part3} & {
        background-color: $c-s-3;
      }

      #{$part4} & {
        background-color: $c-s-4;
      }
    }

    &::-ms-track {
      width: 100%;
      height: $track-height;
      padding: 1rem 0;
      color: transparent;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: $track-color;
    }

    &::-ms-thumb {
      width: $handle-width * 0.8;
      height: $handle-height * 0.8;
      margin-top: 0;
      cursor: pointer;
      border: none;
      border-radius: 50%;

      #{$part1} & {
        background-color: $c-s-1;
      }

      #{$part2} & {
        background-color: $c-s-2;
      }

      #{$part3} & {
        background-color: $c-s-3;
      }

      #{$part4} & {
        background-color: $c-s-4;
      }
    }
  }

  &__value {
    position: relative;
    display: inline-block;
    width: 3rem;
    margin-left: 1rem;
    color: $c-text;
    text-align: center;

    #{$part1} & {
      background-color: $c-s-1;

      &:after {
        border-right-color: $c-s-1;
      }
    }

    #{$part2} & {
      background-color: $c-s-2;

      &:after {
        border-right-color: $c-s-2;
      }
    }

    #{$part3} & {
      background-color: $c-s-3;

      &:after {
        border-right-color: $c-s-3;
      }
    }

    #{$part4} & {
      background-color: $c-s-4;

      &:after {
        border-right-color: $c-s-4;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: -0.7rem;
      width: 0;
      height: 0;
      border-top: 1rem solid transparent;
      border-right: 0.7rem solid transparent;
      border-bottom: 1rem solid transparent;
      content: "";
    }
  }
}
