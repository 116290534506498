@mixin rm-last-margin() {
  > *:last-child {
    margin-bottom: 0;
  }
}

@mixin states($selector: null) {
  @if ($selector != null) {
    &:active, &:focus, &:hover, #{$selector} {
      @content;
    }
  } @else {
    &:active, &:focus, &:hover {
      @content;
    }
  }
}

@mixin button-states {
  &:hover, &:focus, &:active, &:active:focus, &:active:hover {
    @content;
  }
}

@mixin fa-free-solid($icon) {
  @include fa;

  font-family: $f-fa-free;
  font-weight: 900;
  content: $icon;
}

@mixin fa-free-regular($icon) {
  @include fa;

  font-family: $f-fa-free;
  content: $icon;
}

@mixin fa-brands($icon) {
  @include fa;

  font-family: $f-fa-brands;
  content: $icon;
}

@mixin more {
  $self: &;

  text-align: right;

  &__link {
    text-decoration: none;

    &::after {
      font-family: $f-fontawesome;
      content: "\a0\f152";
    }

    @include states {
      #{$self}__label {
        text-decoration: none;
      }
    }
  }

  &__label {
    text-decoration: underline;
  }
}

@mixin fontsmoothing() {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin label() {
  display: inline-block;
  max-width: 100%;
  margin-bottom: $l-input-padding-y;
  font-weight: 700;
}

@mixin input() {
  display: block;
  width: 100%;
  height: $l-input-height;
  padding: $l-input-padding;
  font-size: $t-n-font-size;
  line-height: $t-base-line-height;
  color: $c-text;
  background-color: $c-5;
  border: $l-input-border solid $c-8;
  border-radius: $l-input-border-radius;
  outline: 0;
  box-shadow: none;
  transition: all $a-duration $a-function;
  appearance: none;

  &:focus {
    border-color: $c-1;
    outline: none;
    box-shadow: none;
  }

  &--error {
    border-color: $c-error;
  }
}

@mixin error() {
  padding: $l-input-padding;
  margin-top: ($t-space-line * 0.5);
  color: $c-error;
  border: $l-input-border dashed $c-error;
}

@mixin button() {
  @include button-states {
    color: $c-3;
    background: 0 0;
    outline: 0;
    box-shadow: none;
  }

  padding: 0;
  margin: 0;
  font-size: $t-n-font-size;
  line-height: $t-base-line-height;
  color: $c-2;
  text-transform: uppercase;
  background: 0 0;
  border: solid;
  border-width: 2px;
  border-radius: 0px;
  box-shadow: none;
  transition: all $a-duration $a-function;

  &:before,
  &:after {
    display: inline-block;
    height: 1em;
    overflow: hidden;
    font-family: $t-icon-font;
  }

  &:before {
    width: 0.7em;
    margin-right: 0.2em;
    content: "\f178";
  }

  &:after {
    width: 1em;
    margin-left: 0.2em;
    content: "\f178";
  }
}

@mixin media($query) {
  @media screen and (min-width: $query) {
    @content
  }
}
