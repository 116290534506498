/* orbitron-regular - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/orbitron-v10-latin-regular.woff2") format("woff2"), url("../fonts/orbitron-v10-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* orbitron-500 - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/orbitron-v10-latin-500.woff2") format("woff2"), url("../fonts/orbitron-v10-latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* orbitron-700 - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/orbitron-v10-latin-700.woff2") format("woff2"), url("../fonts/orbitron-v10-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* orbitron-900 - latin */
@font-face {
  font-family: Orbitron;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/orbitron-v10-latin-900.woff2") format("woff2"), url("../fonts/orbitron-v10-latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* OpenSans-regular - latin */
@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v16-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v16-latin-regular.woff2") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: FontAwesome;
  font-weight: 900;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff");
}

.fab {
  font-family: FontAwesome;
}

*, ::after, ::before {
  box-sizing: border-box;
}

html, body {
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

html {
  height: 100%;
  overflow-y: auto;
  font-size: 62.5%;
}

body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: OpenSans, normal;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #000;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2.08rem;
  font-family: Orbitron, sans-serif;
  line-height: 1;
  color: #005a90;
}

h1 {
  font-size: 4.4rem;
}

h2 {
  font-size: 3.6rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.6rem;
}

a {
  display: inline-block;
  color: #777;
  transition: all 0.3s ease;
}

a:active, a:focus, a:hover {
  color: #005a90;
  text-decoration: none;
}

*::selection {
  color: #fff;
  background: #007ac3;
}

img::selection {
  background: none;
}

.second-headline {
  font-size: 2.2rem;
  color: #000;
}

.site-header {
  flex-shrink: 0;
}

.site-header__logo-wrap {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.site-header__logo-wrap a {
  display: block;
}

.site-header__logo {
  display: block;
  width: 100%;
  height: auto;
}

.site-main {
  flex: 1 0 auto;
}

.contact-info {
  padding: 0;
  margin: 0;
  line-height: 2.6rem;
  text-align: center;
  list-style: none;
  color: #005a90;
}

@media screen and (min-width: 48em) {
  .contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contact-info__item {
  padding: 1rem 0;
  color: #005a90;
}

.contact-info__icon {
  padding: 0 1rem;
  color: #005a90;
}

.contact-info__link {
  color: #005a90;
}

.contact-info__link:active, .contact-info__link:focus, .contact-info__link:hover {
  color: #005a90;
}

.btn-default {
  color: #fff;
  background-color: #005a90;
  border: 0.2rem solid #005a90 !important;
}

.btn {
  display: inline-block;
  padding: 1rem 1rem;
  font-family: Orbitron,sans-serif;
  font-weight: 700;
  line-height: 1.25;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  border-radius: 0;
}

.btn, .btn:active, .btn:focus {
  text-decoration: none;
}

.btn:hover {
  background-color: #fff;
  color: #005a90;
}

.site-footer__section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-top: 4em;
  color: #fff;
}

@media screen and (min-width: tablet) {
  .site-footer__section {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.site-footer__section--upper {
  background-color: #000;
}

.site-footer__section--lower {
  background-color: #005a90;
}

.site-footer__section--lower, .site-footer__section--lower a {
  color: #fff;
}

.site-footer__section--lower a[href^=tel]:active, .site-footer__section--lower a[href^=tel]:focus, .site-footer__section--lower a[href^=tel]:hover, .site-footer__section--lower a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

.site-footer__section--lower a:active, .site-footer__section--lower a:focus, .site-footer__section--lower a:hover, .site-footer__section--lower a {
  text-decoration: none;
}

.site-footer__section--lower a:active, .site-footer__section--lower a:focus, .site-footer__section--lower a:hover {
  color: #000;
}

.socialbar {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.socialbar__item {
  margin-right: 0.8rem;
}

.socialbar__item:last-child {
  margin-right: 0;
}

.socialbar__link:active, .socialbar__link:focus, .socialbar__link:hover {
  color: #000;
}

@media screen and (min-width: phablet) {
  .site-footer .socialbar {
    justify-content: center;
  }
}

@media screen and (min-width: desktop) {
  .site-footer .socialbar {
    justify-content: flex-start;
  }
}

.address {
  color: #fff;
  font-family: OpenSans;
  font-weight: normal;
}

.address__paragraph {
  margin-bottom: 0;
}

@media screen and (min-width: phablet) {
  .address__paragraph {
    display: flex;
    flex-wrap: wrap;
  }
}

.address__item {
  display: block;
}

@media screen and (min-width: phablet) {
  .address__item::after {
    content: "\7c";
    color: #fff;
  }
  .address__item:last-child::after {
    content: none;
    color: #fff;
  }
}

.address__item--title {
  font-family: Orbitron, sans-serif;
  font-weight: 900;
  width: 100%;
  color: #fff;
}

.address__item--title:after {
  font-family: OpenSans, normal;
  font-weight: 400;
  color: #fff;
  content: " ";
}

.address__item--city {
  width: 50%;
  color: #fff;
}

.address__item--city:after {
  font-family: OpenSans, normal;
  font-weight: 400;
  color: #fff;
  content: " ";
}

.address__item--citytwo {
  margin-bottom: 2rem;
  width: 50%;
  color: #fff;
}

.site-footer .address .address__paragraph {
  justify-content: center;
}

@media screen and (min-width: desktop) {
  .site-footer .address .address__paragraph {
    justify-content: flex-start;
  }
}

.form--registration {
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 48em) {
  .form--registration {
    max-width: 70%;
  }
}

.form__checkBoxText {
  font-size: 1.5rem;
}

.form__group {
  margin-bottom: 1.56rem;
}

.form__label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.6rem;
  font-weight: 700;
}

.form__input {
  display: block;
  width: 100%;
  height: 3.4rem;
  padding: 0.6rem 1.2rem;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
  border: 0.1rem solid #ccc;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  transition: all 0.3s ease;
  appearance: none;
  background-color: rgba(0, 90, 144, 0.2);
}

.form__input:focus {
  border-color: #005a90;
  outline: none;
  box-shadow: none;
}

.form__input--error {
  border-color: #b94a48;
}

.form__error {
  padding: 0.6rem 1.2rem;
  margin-top: 1.04rem;
  color: #b94a48;
  border: 0.1rem dashed #b94a48;
}

.form__button {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #005a90;
  text-transform: uppercase;
  background: 0 0;
  border: solid;
  border-width: 2px;
  border-radius: 0px;
  box-shadow: none;
  transition: all 0.3s ease;
}

.form__button:hover, .form__button:focus, .form__button:active, .form__button:active:focus, .form__button:active:hover {
  color: #009ee0;
  background: 0 0;
  outline: 0;
  box-shadow: none;
}

.form__button:before, .form__button:after {
  display: inline-block;
  height: 1em;
  overflow: hidden;
  font-family: FontAwesome;
}

.form__button:before {
  width: 0.7em;
  margin-right: 0.2em;
  content: "\f178";
}

.form__button:after {
  width: 1em;
  margin-left: 0.2em;
  content: "\f178";
}

.admin .graph {
  display: block;
  width: 100%;
  height: auto;
}

.admin .table .dropdown-menu {
  right: 0;
  left: auto;
}

.admin .table .dropdown-menu a {
  border: none;
}

.admin .table .btn-group {
  white-space: nowrap;
}

.admin .table .btn-group .btn {
  float: none;
}

.survey__participant {
  font-family: OpenSans;
  font-weight: bold;
  font-size: 4rem;
  color: #000;
}

.survey__please {
  font-family: OpenSans;
  font-weight: bold;
  font-size: large;
  color: #777;
}

.survey__group__header {
  padding: 2rem 1.5rem;
  margin: 3rem 0 2rem;
  text-align: center;
}

.survey__group--1 .survey__group__header {
  background-color: #4499ba;
}

.survey__group--2 .survey__group__header {
  background-color: #a88aa0;
}

.survey__group--3 .survey__group__header {
  background-color: #bcb68d;
}

.survey__group--4 .survey__group__header {
  background-color: #b58a79;
}

.survey__group__head-line {
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
}

.survey__form-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 7rem;
  margin-bottom: 2rem;
}

.survey__question {
  width: 100%;
  padding-right: 2rem;
  margin: 0 0 1rem;
  font-family: OpenSans;
  font-weight: bold;
  color: #005a90;
}

@media screen and (min-width: 62em) {
  .survey__question {
    width: 60%;
    margin: 0;
  }
}

.survey__question__title {
  font-weight: 700;
}

.survey__input-group {
  width: 100%;
  margin-bottom: 2rem;
}

@media screen and (min-width: 62em) {
  .survey__input-group {
    width: 40%;
    margin: 0;
  }
}

.survey__submit-wrap {
  margin: 2.08rem 0;
  text-align: left;
}

.survey__submit {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #005a90;
  text-transform: uppercase;
  background: 0 0;
  border: solid;
  border-width: 2px;
  border-radius: 0px;
  box-shadow: none;
  transition: all 0.3s ease;
  font-size: 2.4rem;
}

.survey__submit:hover, .survey__submit:focus, .survey__submit:active, .survey__submit:active:focus, .survey__submit:active:hover {
  color: #009ee0;
  background: 0 0;
  outline: 0;
  box-shadow: none;
}

.survey__submit:before, .survey__submit:after {
  display: inline-block;
  height: 1em;
  overflow: hidden;
  font-family: FontAwesome;
}

.survey__submit:before {
  width: 0.7em;
  margin-right: 0.2em;
  content: "\f178";
}

.survey__submit:after {
  width: 1em;
  margin-left: 0.2em;
  content: "\f178";
}

.range-slider {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.range-slider__range {
  flex-grow: 1;
  padding: 1rem 0;
  margin: 0;
  cursor: pointer;
  background: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

.range-slider__range:focus {
  outline: none;
}

.range-slider__range::-webkit-slider-runnable-track {
  height: 0.5rem;
  background: #ccc;
}

.range-slider__range::-webkit-slider-thumb {
  width: 2rem;
  height: 2rem;
  margin-top: -0.7rem;
  cursor: pointer;
  border-radius: 50%;
  appearance: none;
}

.survey__group--1 .range-slider__range::-webkit-slider-thumb {
  background-color: #4499ba;
}

.survey__group--2 .range-slider__range::-webkit-slider-thumb {
  background-color: #a88aa0;
}

.survey__group--3 .range-slider__range::-webkit-slider-thumb {
  background-color: #bcb68d;
}

.survey__group--4 .range-slider__range::-webkit-slider-thumb {
  background-color: #b58a79;
}

.range-slider__range::-moz-range-track {
  background: #ccc;
}

.range-slider__range::-moz-range-thumb {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}

.survey__group--1 .range-slider__range::-moz-range-thumb {
  background-color: #4499ba;
}

.survey__group--2 .range-slider__range::-moz-range-thumb {
  background-color: #a88aa0;
}

.survey__group--3 .range-slider__range::-moz-range-thumb {
  background-color: #bcb68d;
}

.survey__group--4 .range-slider__range::-moz-range-thumb {
  background-color: #b58a79;
}

.range-slider__range::-ms-track {
  width: 100%;
  height: 0.5rem;
  padding: 1rem 0;
  color: transparent;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
}

.range-slider__range::-ms-fill-lower {
  background: #ccc;
}

.range-slider__range::-ms-fill-upper {
  background: #ccc;
}

.range-slider__range::-ms-thumb {
  width: 1.6rem;
  height: 1.6rem;
  margin-top: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}

.survey__group--1 .range-slider__range::-ms-thumb {
  background-color: #4499ba;
}

.survey__group--2 .range-slider__range::-ms-thumb {
  background-color: #a88aa0;
}

.survey__group--3 .range-slider__range::-ms-thumb {
  background-color: #bcb68d;
}

.survey__group--4 .range-slider__range::-ms-thumb {
  background-color: #b58a79;
}

.range-slider__value {
  position: relative;
  display: inline-block;
  width: 3rem;
  margin-left: 1rem;
  color: #000;
  text-align: center;
}

.survey__group--1 .range-slider__value {
  background-color: #4499ba;
}

.survey__group--1 .range-slider__value:after {
  border-right-color: #4499ba;
}

.survey__group--2 .range-slider__value {
  background-color: #a88aa0;
}

.survey__group--2 .range-slider__value:after {
  border-right-color: #a88aa0;
}

.survey__group--3 .range-slider__value {
  background-color: #bcb68d;
}

.survey__group--3 .range-slider__value:after {
  border-right-color: #bcb68d;
}

.survey__group--4 .range-slider__value {
  background-color: #b58a79;
}

.survey__group--4 .range-slider__value:after {
  border-right-color: #b58a79;
}

.range-slider__value:after {
  position: absolute;
  top: 0;
  left: -0.7rem;
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 1rem solid transparent;
  content: "";
}

.result__graph-wrap {
  width: 100%;
}

@media screen and (min-width: 48em) {
  .result__graph-wrap--detail {
    width: 75%;
    margin: 0 auto;
  }
}

.result__graph {
  display: block;
  width: 100%;
  height: auto;
}

.result__header {
  margin-bottom: 2.08rem;
}

.result__download-wrap {
  margin-top: 2.08rem;
  text-align: center;
}

@media screen and (min-width: 48em) {
  .result__download-wrap:not(.result__download-wrap--first) {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    margin-top: 0;
    transform: translateY(-50%);
  }
}

.result__download-wrap:not(.result__download-wrap--first) a {
  color: #fff;
}

.result__download-wrap:not(.result__download-wrap--first) a:active, .result__download-wrap:not(.result__download-wrap--first) a:focus, .result__download-wrap:not(.result__download-wrap--first) a:hover {
  color: #005a90;
}

.result__legend {
  text-align: center;
}

.result__graph-call {
  text-align: center;
}

.result__group {
  margin-bottom: 4rem;
}

.result__group__header {
  position: relative;
  padding: 2rem 1.5rem;
  margin: 3rem 0 2rem;
  text-align: center;
}

.result__group--1 .result__group__header {
  background-color: #4499ba;
}

.result__group--2 .result__group__header {
  background-color: #a88aa0;
}

.result__group--3 .result__group__header {
  background-color: #bcb68d;
}

.result__group--4 .result__group__header {
  background-color: #b58a79;
}

.result__group__head-line {
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
}
