.admin {
  .graph {
    display: block;
    width: 100%;
    height: auto;
  }

  .table {
    .dropdown-menu {
      right: 0;
      left: auto;

      a {
        border: none;
      }
    }

    .btn-group {
      white-space: nowrap;

      .btn {
        float: none;
      }
    }
  }
}
